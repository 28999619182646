import { CookieAttributes } from 'js-cookie';
import { REACT_APP_ENVIRONMENT } from '../config/environment';
import { COOKIES_PREFIX } from '../config/settings';

const buildKey = (key: string) => `${COOKIES_PREFIX}_${REACT_APP_ENVIRONMENT}_${key}`;

export const getCookie = (key: string): string | null => localStorage.getItem(buildKey(key));

export const removeCookie = (key: string, options: CookieAttributes = {}): void => {
  const itemKey = buildKey(key);
  localStorage.removeItem(itemKey);
};

export const setCookie = <V extends string>(
  key: string,
  value: V,
  options: CookieAttributes = {}
): void => {
  const itemKey = buildKey(key);

  localStorage.setItem(itemKey, value);
};
