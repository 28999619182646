import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PATH_DASHBOARD } from '../routes/paths';
import { useOrderDispatch } from '../contexts/Orders/useOrderDispatch';

const allowPathname = [PATH_DASHBOARD.orders.client, PATH_DASHBOARD.orders.create];

const useOrderInfoCleanUp = () => {
  const { pathname } = useLocation();
  const orderDispatcher = useOrderDispatch();

  useEffect(() => {
    const isAlloedPathname = allowPathname.find((currentPathname) => currentPathname === pathname);
    if (!isAlloedPathname) {
      orderDispatcher({ type: 'reset' });
    }
  }, [pathname, orderDispatcher]);
};

export default useOrderInfoCleanUp;
