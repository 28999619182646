import { useContext } from 'react';
import { NotificationsContext } from '../contexts/NotificationsContext';
// ----------------------------------------------------------------------

const useNotifications = () => {
  const context = useContext(NotificationsContext);

  if (!context) throw new Error('Notifications context must be use inside NotificationProvider');

  return context;
};

export default useNotifications;
