// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT_DASHBOARD = '/dashboard';
const ROOT_AUTH = '/auth';
const ROOT_USER = '/user';
const ROOT_ORDER = '/order';
const ROOT_NOTIFICATIONS = '/notifications';
const ROOT_HISTORY = '/history';

export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, '/login'),
  loginUnprotected: path(ROOT_AUTH, '/login-unprotected'),
  register: path(ROOT_AUTH, '/register'),
  registerUnprotected: path(ROOT_AUTH, '/register-unprotected'),
  resetPassword: path(ROOT_AUTH, '/reset-password'),
  verify: path(ROOT_AUTH, '/verify')
};

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOT_DASHBOARD,
  orders: {
    root: ROOT_DASHBOARD,
    list: ROOT_DASHBOARD,
    client: path(ROOT_ORDER, '/client'),
    create: path(ROOT_ORDER, '/create'),
    edit: (id: string) => path(ROOT_ORDER, `/${id}/edit`)
  },
  notifications: {
    root: ROOT_NOTIFICATIONS,
    list: path(ROOT_NOTIFICATIONS, '/list')
  },
  app: {
    root: path(ROOT_DASHBOARD, '/app'),
    pageFour: path(ROOT_DASHBOARD, '/app/four'),
    pageFive: path(ROOT_DASHBOARD, '/app/five'),
    pageSix: path(ROOT_DASHBOARD, '/app/six')
  },
  user: {
    root: ROOT_USER,
    profile: path(ROOT_USER, '/profile'),
    list: path(ROOT_USER, '/list'),
    create: path(ROOT_USER, '/create'),
    edit: (id: string) => path(ROOT_USER, `/${id}/edit`)
  },
  history: {
    root: ROOT_HISTORY,
    list: path(ROOT_HISTORY, '/list')
  }
};
