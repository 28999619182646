// material
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src="/static/brand/logo.png" alt="Llanquihue Logo" title="Cafe Llanquihue" />
    </Box>
  );
}
