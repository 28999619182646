import { createContext, Dispatch } from 'react';
import { TOrderContexStore, TOrderBusinessLogic, DispatchAction } from './types';

export const orderInitialState: TOrderContexStore = {
  clients: [],
  client: undefined,
  order: undefined,
  loading: false,
  orders: []
};

const notImplemented = (msgError: string) => () => {
  throw new Error(msgError);
};

const orderBusinessLogicState: TOrderBusinessLogic = {
  // useGetOrder: () => [false, undefined, notImplemented('getOrders is not implemented')],
  useClient: () => [false, undefined, notImplemented('getClientById is not implemented')]
  // getOrders: notImplemented('getOrders is not implemented'),
  // getOrderById: notImplemented('getOrderById is not implemented'),
  // getClientById: notImplemented('getClientById is not implemented')
  // getProducts: notImplemented('getProducts is not implemented'),
  // getVariantsByProductId: notImplemented('getVariantsByProductId is not implemented'),
  // saveOrder: notImplemented('saveOrder is not implemented')
};

export const OrderContext = createContext<TOrderContexStore>(orderInitialState);
export const OrderDispatchContext = createContext<Dispatch<DispatchAction>>(() => {
  throw new Error('OrderDispatchContext not implemented');
});
export const OrderBusinessLogicContext =
  createContext<TOrderBusinessLogic>(orderBusinessLogicState);
