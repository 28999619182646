import { getCookie, removeCookie, setCookie } from './cookies';

const SESSION_COOKIE_KEY = 'session';

export const setSession = (token: string) => {
  setCookie(SESSION_COOKIE_KEY, token);
};

export const getSession = (): string | null => getCookie(SESSION_COOKIE_KEY);

export const hasSession = (): boolean => !!getSession();

export const clearSession = () => {
  removeCookie(SESSION_COOKIE_KEY);
};
