import { createContext, useReducer, FC } from 'react';

export const ProductDispatchContext = createContext<any>(null);

const initialState = {
  products: [],
  loading: false,
  error: null,
  query: '',
  page: 1,
  limit: 10,
  total: 0,
  nextPage: false,
  fullyLoaded: false
};

export const ProductContext = createContext<any>(initialState);

/*
getProducts({ query, page, limit })
*/

export const ACTIONS = {
  FETCH_STATE: 'FETCH_STATE',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  SET_PRODUCTS: 'SET_PRODUCTS',
  ADD_PRODUCTS: 'ADD_PRODUCTS',
  FETCH_ERROR: 'FETCH_ERROR'
};

const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.FETCH_STATE:
      return { ...state, loading: action.payload.loading };
    case ACTIONS.FETCH_ERROR:
      return { ...state, error: action.payload.error };
    case ACTIONS.SET_QUERY_PARAMS: {
      const { limit, query, page } = action.payload;
      return { ...state, limit, query, page };
    }
    case ACTIONS.SET_PRODUCTS: {
      const { products, total, nextPage } = action.payload;
      return { ...state, products, total, nextPage };
    }
    case ACTIONS.ADD_PRODUCTS: {
      const { products: newProducts, total, nextPage } = action.payload;
      const { products } = state;
      const currentProducts = products.concat(newProducts);
      return { ...state, products: currentProducts, total, nextPage, fullyLoaded: !nextPage };
    }
    default:
      return state;
  }
};

export const ProductsProvider: FC = ({ children }) => {
  const [api, dispatch] = useReducer(Reducer, initialState);
  return (
    <ProductDispatchContext.Provider value={dispatch}>
      <ProductContext.Provider value={api}>{children}</ProductContext.Provider>
    </ProductDispatchContext.Provider>
  );
};
