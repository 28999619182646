// routes
import { useContext } from 'react';
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';
import { AuthContext } from './contexts/JWTContext';
import './print.css';

// ----------------------------------------------------------------------

export default function App() {
  const auth = useContext(AuthContext);

  if (!auth.isInitialized) {
    return <div />;
  }

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
