/* eslint-disable import/no-duplicates */
import { noCase } from 'change-case';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import es from 'date-fns/locale/es';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography
} from '@material-ui/core';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
// hooks
import useNotifications from '../../hooks/useNotifications';
import {
  INotification,
  INotificationStatus,
  INotificationType
} from '../../services/notifications';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

function renderContent(notification: INotification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="div" variant="body2" sx={{ color: 'text.secondary' }}>
        {noCase(notification.description || '')}
      </Typography>
    </Typography>
  );

  if (notification.type === INotificationType.ot_new) {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  if (notification.type === INotificationType.ot_take) {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === INotificationType.ot_dispatched) {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === INotificationType.ot_document) {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title: (
        <Typography variant="subtitle2">
          {notification.title}
          <Typography component="div" variant="body2" sx={{ color: 'text.secondary' }}>
            {noCase(notification.description || '')}
          </Typography>
          <Typography component="div" variant="body2" sx={{ color: 'text.secondary' }}>
            <a href={notification.url || ''}>Ver Documento</a>
          </Typography>
        </Typography>
      )
    };
  }

  return {
    avatar: null,
    title
  };
}

function NotificationItem({
  notification,
  onCheck
}: {
  notification: INotification;
  onCheck: (id: string) => void;
}) {
  const { avatar, title } = renderContent(notification);
  const { type, objectId, status, id, url } = notification;
  const navigate = useNavigate();
  const dir = useMemo(() => {
    if (type === INotificationType.ot_new || type === INotificationType.ot_dispatched) {
      return PATH_DASHBOARD.orders.edit(objectId);
    }

    return '#';
  }, [type, objectId]);

  const handleOnClickNotification = useCallback(() => {
    if (status === INotificationStatus.unread) {
      onCheck(id);
    }

    if (type === INotificationType.ot_document) {
      window.open(url, '_blank');
      return;
    }
    navigate(dir);
  }, [status, type, navigate, dir, onCheck, id, url]);

  return (
    <ListItemButton
      to={dir}
      onClick={handleOnClickNotification}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.status === INotificationStatus.read && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(notification.createdAt), { locale: es })}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { notifications, viewAllNotifications, viewNotification } = useNotifications();
  const totalUnRead = useMemo(
    () => notifications.filter((item) => item.status === INotificationStatus.unread).length,
    [notifications]
  );

  const notificationsUnchecked = useMemo(
    () => notifications.filter((item) => item.status === INotificationStatus.unread),
    [notifications]
  );

  const notificationsChecked = useMemo(
    () => notifications.filter((item) => item.status === INotificationStatus.read),
    [notifications]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleMarkAllAsRead = useCallback(() => {
    viewAllNotifications();
  }, [viewAllNotifications]);

  const handleGoToNotifications = useCallback(() => {
    navigate(PATH_DASHBOARD.notifications.root);
  }, [navigate]);

  const hasMoreUncheckNotifications = useMemo(
    () => notificationsUnchecked.length > notificationsUnchecked.slice(0, 5).length,
    [notificationsUnchecked]
  );

  const hasMoreCheckNotifications = useMemo(
    () => notificationsChecked.length > notificationsChecked.slice(0, 3).length,
    [notificationsChecked]
  );

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificaciones</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Tienes {totalUnRead} {totalUnRead === 1 ? 'notificacion' : 'notificaciones'} sin leer
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Marcar todos como leídas">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        {!!notifications.length && (
          <Scrollbar sx={{ height: { xs: 340 } }}>
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Sin leer
                </ListSubheader>
              }
            >
              {notificationsUnchecked.slice(0, 5).map((notification) => (
                <NotificationItem
                  onCheck={viewNotification}
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </List>

            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Leídas
                </ListSubheader>
              }
            >
              {notificationsChecked.slice(0, 3).map((notification) => (
                <NotificationItem
                  onCheck={viewNotification}
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </List>
          </Scrollbar>
        )}
        {(hasMoreUncheckNotifications || hasMoreCheckNotifications) && (
          <>
            <Divider />

            <Box sx={{ p: 1 }}>
              <Button fullWidth disableRipple onClick={handleGoToNotifications}>
                Ver todas
              </Button>
            </Box>
          </>
        )}
      </MenuPopover>
    </>
  );
}
