import qs from 'qs';
import {
  deleteJsonRequest,
  getJsonRequest,
  patchJsonRequest,
  postJsonRequest
} from '../utils/fetch';
import { IUserLogged } from './auth';

export enum Genders {
  'masculine',
  'feminine',
  'other'
}
export enum Status {
  'default',
  'active',
  'banned'
}

export const StatusName: { [key: string]: string } = {
  1: 'Activo',
  2: 'No Activo'
};

export interface IUser {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  status: number;
  gender: Genders;
  role: string;
  createdAt: string;
  updatedAt: string;
  sellerId?: number;
  sellerProviderId: string;
}

export type IUserProfile = Pick<IUser, 'firstname' | 'lastname'>;

export type IUserPassword = {
  current_password: string;
  password: string;
};

export const userTemplate = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  username: '',
  status: 0,
  gender: Genders.masculine,
  role: '',
  createdAt: '',
  updatedAt: '',
  sellerProviderId: ''
};

export type TGetUserListParams = {
  query?: string;
  page?: number;
  limit?: number;
  order?: 'asc' | 'desc';
  field?: string;
};

export type TGetUserList = (
  params: TGetUserListParams
) => Promise<{ items: IUser[]; total: number }>;

export type TUpdateProfile = (attributes: IUserProfile) => Promise<IUser>;
export type TChangePassword = (data: IUserPassword) => Promise<IUser>;

export const getUser = (id: string): Promise<IUser> => getJsonRequest<IUser>(`user/${id}`);

type IUserLoggedInfo = Exclude<IUserLogged, 'token'>;

export const getMe = (): Promise<IUserLoggedInfo> => getJsonRequest<IUserLoggedInfo>('user/me');

export const getUserList: TGetUserList = (queries) => {
  const params = qs.stringify(queries);
  return getJsonRequest<{ items: IUser[]; total: number }>(`user/list?${params.toString()}`);
};

export const searchUsers = (query: string) => {
  const params = qs.stringify({ query });
  return getJsonRequest<{ items: IUser[]; total: number }>(`user/search?${params.toString()}`);
};

interface ICreateUserResponse {
  success: boolean;
  data: IUser[];
  message: string;
  error: string;
}

export interface ICreateUser {
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  status: number;
  role: string;
}

interface IUpdateUserResponse {
  success: boolean;
  user: ICreateUser;
  message: string;
  error: string;
}

interface IPasswordGenerationResponse {
  password: string;
}

export const createUser = (user: ICreateUser): Promise<ICreateUserResponse> =>
  postJsonRequest<ICreateUser, ICreateUserResponse>('user/create', user);

export const updateUser = (id: string, user: Partial<ICreateUser>): Promise<IUpdateUserResponse> =>
  patchJsonRequest<Partial<ICreateUser>, IUpdateUserResponse>(`user/update/${id}`, user);

export const updateProfile: TUpdateProfile = (attributes) =>
  patchJsonRequest<IUserProfile, IUser>('user/me', attributes);

export const changePassword: TChangePassword = (data) =>
  patchJsonRequest<IUserPassword, IUser>('user/changePassword', data);

export const deleteUser = (id: string) => deleteJsonRequest<null>(`user/delete/${id}`);

export const generatePassword = (id: string) =>
  postJsonRequest<object, IPasswordGenerationResponse>(`user/${id}/regeneratePassword`, {});
