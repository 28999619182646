import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  const isValid = decoded.exp > currentTime;

  return { isValid, decoded };
};

export const decodeToken = (accessToken: string) =>
  jwtDecode<{ exp: number; id: string }>(accessToken);

export { isValidToken, verify, sign };
