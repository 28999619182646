import { createContext, useReducer, FC } from 'react';
import { TStatus } from '../../services/types';

export const StatusDispatchContext = createContext<any>(null);

const initialState = {
  status: [],
  byIds: {}
};

type TByIds = { [key: number]: TStatus };
type TStatusInitialState = {
  status: TStatus[];
  byIds: TByIds;
};
export const StatusContext = createContext<TStatusInitialState>(initialState);

export const ACTIONS = {
  SET_STATUSES: 'SET_STATUSES'
};

const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_STATUSES: {
      const { status } = action.payload;
      const byIds = status.reduce(
        (acc: TByIds, item: TStatus) => ({ ...acc, [item.id]: item }),
        {}
      );
      return { ...state, status, byIds };
    }
    default:
      return state;
  }
};

export const StatusProvider: FC = ({ children }) => {
  const [api, dispatch] = useReducer(Reducer, initialState);
  return (
    <StatusDispatchContext.Provider value={dispatch}>
      <StatusContext.Provider value={api}>{children}</StatusContext.Provider>
    </StatusDispatchContext.Provider>
  );
};
