// import { TResolver, TResolverMap } from './types';
import { TResolver } from './types';
import { orderInitialState } from './orderContext';

// const loadingResolver: TResolver = (state, action) => ({
//   ...state,
//   loading: action.payload.loading
// });

// const resolvers: TResolverMap = {
//   loading: loadingResolver,
//   default: (store) => store
// };

export const OrderReducer: TResolver = (store, action) => {
  const { type } = action;
  switch (type) {
    case 'setOrders': {
      const {
        payload: { orders, total, page }
      } = action;
      return { ...store, orders, total, page };
    }
    case 'setClients': {
      const {
        payload: { clients }
      } = action;
      return { ...store, clients };
    }
    case 'setClient': {
      const {
        payload: { clientId }
      } = action;

      const { clients } = store;

      const findClientIndex = clients.find((client) => client.id === clientId);
      if (!findClientIndex) {
        return store;
      }

      const client = findClientIndex;

      return { ...store, client };
    }
    case 'reset':
      return orderInitialState;
    default:
      return store;
  }
};
// export const OrderReducer: TResolver = (store, action) => {
//   const { type } = action;
//   const resolver = resolvers[type] || resolvers.default;
//   return resolver(store, action);
// };
