// eslint-disable-next-line max-classes-per-file
import { REACT_APP_API_BASE_URL } from '../config/environment';
import Log from './logger';
import { getSession } from './session';

const log = Log.getLogger('fetch');

type THeaders = Record<string, string>;

enum ErrorReason {
  BadRequest = 400,
  NoContent = 204,
  RefreshSession = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  NotKnown = 500,
  NotImplemented = 501,
  ServiceUnavailable = 503,
  Retry = 429
}

type HTTPErrorResponseExtras = {
  extra: { [key: string]: any };
  statusCode: number;
  url: string;
};

class HTTPErrorResponse extends Error {
  public extra: { [key: string]: any };

  public url: string;

  public statusCode: number;

  constructor(message: string, extras: HTTPErrorResponseExtras) {
    super(message);
    this.url = extras.url;
    this.extra = extras.extra;
    this.statusCode = extras.statusCode;
  }
}

class ProtocolError {
  public errorReason: ErrorReason | undefined;

  public errorDetails: string | undefined;

  // @ts-ignore
  constructor(args?: { errorReason?: ErrorReason; errorDetails: string });
}

const processErrorResponse = (response: Response): Promise<never> => {
  if (response.status === ErrorReason.RefreshSession) {
    // login Redirect
    return Promise.reject();
  }

  // JSON error response
  console.log('response', response.headers.get('Content-Type'));
  if (String(response.headers.get('Content-Type')).indexOf('application/json') > -1) {
    return response.json().then((error) => {
      const err = new HTTPErrorResponse(error.message, {
        url: response.url,
        statusCode: response.status,
        extra: error
      });
      log.error('processJsonResponse: got an error from:', response.url, ', error:', error);

      throw err;
    });
  }

  // Text error response
  if (response.headers.get('Content-Type')) {
    return response.text().then((errorText) => {
      log.error('processJsonResponse: got an error from:', response.url, ', error:', errorText);
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new ProtocolError({ errorReason: response.status, errorDetails: errorText });
    });
  }

  // We could not parse the body, just trow with response status/text
  log.error(
    'processJsonResponse: got an error from:',
    response.url,
    response.status,
    response.statusText
  );
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw new ProtocolError({ errorReason: response.status, errorDetails: response.statusText });
};

function processJsonResponse<T>(response: Response): Promise<T | null> {
  if (response.ok) {
    if (response.status === ErrorReason.NoContent) {
      return Promise.resolve(null);
    }

    return response.json().then((json) => {
      log.debug('processJsonResponse: for url:', response.url, json);
      return json;
    });
  }

  return processErrorResponse(response);
}

const getAuthorizationHeader = () => {
  const sessionToken = getSession();
  return { Authorization: `Bearer ${sessionToken}` };
};

function generateHeaders(isPublic = false, customHeaders?: THeaders): THeaders {
  const headers: THeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...customHeaders
  };

  if (!isPublic) {
    const authHeader = getAuthorizationHeader();
    headers.Authorization = authHeader.Authorization;
  }

  return headers;
}

export function getJsonRequest<R>(
  path: string,
  isPublic?: boolean,
  customAPIUrl?: string
): Promise<R> {
  return window
    .fetch(`${customAPIUrl || REACT_APP_API_BASE_URL}${path}`, {
      headers: generateHeaders(!!isPublic),
      mode: 'cors'
    })
    .then((response) => processJsonResponse<R>(response))
    .then((body) => {
      if (body) return body;
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new ProtocolError({ errorReason: ErrorReason.NotFound, errorDetails: 'Empty body' });
    });
}

export function postJsonRequest<P, R>(
  path: string,
  requestBody: P,
  isPublic?: boolean,
  customAPIUrl?: string
): Promise<R> {
  return window
    .fetch(`${customAPIUrl || REACT_APP_API_BASE_URL}${path}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      mode: 'cors',
      headers: generateHeaders(!!isPublic)
    })
    .then((response) => processJsonResponse<R>(response))
    .then((body) => {
      if (body) return body;
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new ProtocolError({ errorReason: ErrorReason.NotFound, errorDetails: 'Empty body' });
    });
}

export function postFormDataRequest<R>(
  path: string,
  body: FormData,
  customAPIUrl?: string
): Promise<R> {
  return window
    .fetch(`${customAPIUrl || REACT_APP_API_BASE_URL}${path}`, {
      method: 'POST',
      body,
      mode: 'cors',
      headers: getAuthorizationHeader()
    })
    .then((response) => processJsonResponse<R>(response))
    .then((body) => {
      if (body) return body;
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new ProtocolError({ errorReason: ErrorReason.NotFound, errorDetails: 'Empty body' });
    });
}

export function patchJsonRequest<P, R>(
  path: string,
  requestBody: P,
  isPublic?: boolean,
  customAPIUrl?: string
): Promise<R> {
  return window
    .fetch(`${customAPIUrl || REACT_APP_API_BASE_URL}${path}`, {
      method: 'PATCH',
      body: JSON.stringify(requestBody),
      mode: 'cors',
      headers: generateHeaders(!!isPublic)
    })
    .then((response) => processJsonResponse<R>(response))
    .then((body) => body as R);
}

export function deleteJsonRequest<R>(
  path: string,
  isPublic?: boolean,
  customAPIUrl?: string
): Promise<R | null> {
  return window
    .fetch(`${customAPIUrl || REACT_APP_API_BASE_URL}${path}`, {
      headers: generateHeaders(!!isPublic),
      method: 'DELETE',
      mode: 'cors'
    })
    .then((response) => processJsonResponse<R>(response));
}
