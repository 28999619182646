import { createContext, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';
import useAuth from 'hooks/useAuth';
import {
  checkNotification,
  getNotifications,
  INotification,
  IOriginalNotification
} from 'services/notifications';
import { Channel } from 'pusher-js';
import { NotificationsContextProps } from '../@types/notifications';
import { createAction } from '../utils/contextReducers';
import { IReducer } from '../@types/contextReducers';
import pusher from '../utils/pusher';

const initialContext: NotificationsContextProps = {
  notifications: [],
  loadingNotifications: false,
  addNotification: (notification) => undefined,
  viewNotification: (notificationId) => undefined,
  viewAllNotifications: () => undefined
};

const NotificationsContext = createContext(initialContext);

enum notificationsActions {
  GET_NOTIFICATION = 'GET_NOTIFICATION',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  VIEW_NOTIFICATION = 'VIEW_NOTIFICATION',
  VIEW_ALL_NOTIFICATIONS = 'VIEW_ALL_NOTIFICATIONS'
}

interface INotificationsState {
  notifications: INotification[];
}
const notificationsState: INotificationsState = {
  notifications: []
};
const onGetNotifications = createAction<notificationsActions, INotification[]>(
  notificationsActions.GET_NOTIFICATION
);
const onAddNotification = createAction<notificationsActions, INotification>(
  notificationsActions.ADD_NOTIFICATION
);
const onViewNotification = createAction<notificationsActions, { id: string }>(
  notificationsActions.VIEW_NOTIFICATION
);
const onViewAllNotifications = createAction<notificationsActions>(
  notificationsActions.VIEW_ALL_NOTIFICATIONS
);

const notificationsReducer: IReducer<INotificationsState> = (state, { type, payload }) => {
  switch (type) {
    case notificationsActions.GET_NOTIFICATION: {
      const newNotifications = payload as INotification[];

      return { ...state, notifications: newNotifications };
    }
    case notificationsActions.VIEW_NOTIFICATION: {
      const { id } = payload as { id: string };

      const notificationsUpdated = state.notifications.map((notification) => {
        if (notification.id === id) {
          return { ...notification, status: 2 };
        }
        return notification;
      });

      return { ...state, notifications: notificationsUpdated };
    }
    case notificationsActions.ADD_NOTIFICATION: {
      const newNotification = payload as INotification;

      return { ...state, notifications: [...state.notifications, newNotification] };
    }
    case notificationsActions.VIEW_ALL_NOTIFICATIONS: {
      const notificationsUpdated = state.notifications.map((notification) => ({
        ...notification,
        status: 2
      }));
      return { ...state, notifications: notificationsUpdated };
    }
    default: {
      return state;
    }
  }
};

const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [{ notifications }, dispatch] = useReducer(notificationsReducer, notificationsState);

  useEffect(() => {
    let channel: Channel | null;
    if (isAuthenticated && user) {
      channel = pusher.subscribe(`user-${user.id}`);
      channel.bind('new-notification', (newOriginalNotification: IOriginalNotification) => {
        const { object_id, ...newNotification } = newOriginalNotification;
        dispatch(onAddNotification({ ...newNotification, objectId: object_id }));
      });
    }
    return () => {
      if (channel) {
        channel.unbind_all();
      }
    };
  }, [isAuthenticated, user]);

  const setNotifications = useCallback((notifications: INotification[]) => {
    dispatch(onGetNotifications(notifications));
  }, []);

  const addNotification = useCallback((notification: INotification) => {
    dispatch(onAddNotification(notification));
  }, []);

  const viewNotification = useCallback((notificationId: string) => {
    checkNotification(notificationId)
      .then(({ success }) => {
        if (success) {
          dispatch(onViewNotification({ id: notificationId }));
        }
      })
      .catch((e) => console.log('e', e));
  }, []);

  const viewAllNotifications = useCallback(() => {
    dispatch(onViewAllNotifications(undefined));
  }, []);

  const loadNotifications = useCallback(() => {
    if (!isAuthenticated || !isInitialized) {
      return;
    }
    setLoadingNotifications(true);

    getNotifications()
      .then(({ notifications }) => {
        setNotifications(notifications);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingNotifications(false);
      });
  }, [isAuthenticated, isInitialized, setNotifications]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        loadingNotifications,
        addNotification,
        viewNotification,
        viewAllNotifications
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
