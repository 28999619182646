import { useContext } from 'react';
import { OrderDispatchContext } from './orderContext';

export const useOrderDispatch = () => {
  const dispatch = useContext(OrderDispatchContext);
  if (typeof dispatch === 'undefined') {
    throw new Error('useOrderDispatch must to be used inside OrderProvider');
  }
  return dispatch;
};
