import { createContext, ReactNode, useEffect, useReducer } from 'react';
// Services
import * as AuthServices from 'services/auth';
// utils
import { isValidToken } from '../utils/jwt';
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  UserAuthInfo,
  JWTContextType,
  AuthMethod
} from '../@types/authentication';
import { clearSession, getSession, setSession } from '../utils/session';
import {
  getMe,
  TUpdateProfile,
  IUser,
  updateProfile as updateProfileService,
  userTemplate
} from '../services/user';
import { IUserLogged } from '../services/auth';
import useStatusAPI from './Status/useStatusAPI';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  UpdateProfile = 'UPDATE_PROFILE'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.UpdateProfile]: {
    user: Pick<UserAuthInfo, 'fullname' | 'firstname' | 'lastname' | 'email'>;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        isAuthenticated: true,
        user: { ...state.user, ...action.payload.user } as AuthUser
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthInitialState = {
  user: null,
  isAuthenticated: false,
  isInitialized: false,
  method: AuthMethod.jwt,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resetPassword: () => {},
  updateProfile: () => new Promise<IUser>((resolve) => resolve(userTemplate))
};
const AuthContext = createContext<JWTContextType>(AuthInitialState);

const getUserInfo = (user: IUser) => {
  const { firstname, lastname, email } = user;
  const fullname = [firstname, lastname].filter(Boolean).join(' ');
  return {
    firstname,
    lastname,
    fullname,
    email
  };
};

const parseUserInfo = (user: Exclude<IUserLogged, 'token'>) => {
  const {
    firstname,
    lastname,
    role,
    routes,
    links,
    id,
    email,
    grants,
    sellerId,
    sellerProviderId
  } = user;
  const fullname = [firstname, lastname].filter(Boolean).join(' ');
  return {
    role,
    routes,
    links,
    id,
    email,
    grants,
    fullname,
    firstname,
    lastname,
    sellerId,
    sellerProviderId
  };
};

const getUserInfoWithToken = (user: IUserLogged) => ({ token: user.token, ...parseUserInfo(user) });

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { loadStatus } = useStatusAPI();

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = getSession();
        if (token && isValidToken(token)) {
          getMe()
            .then((user) => {
              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: true,
                  user: { token, ...parseUserInfo(user) }
                }
              });
            })
            .then(loadStatus)
            .catch((e) => {
              console.log('error', e);
              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: false,
                  user: null
                }
              });
            });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    const response = await AuthServices.login({
      username,
      password
    });

    const user = getUserInfoWithToken(response);

    setSession(user.token);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    dispatch({ type: Types.Logout });
    clearSession();
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile: TUpdateProfile = (attributes) =>
    updateProfileService(attributes).then((response) => {
      const user = getUserInfo(response);

      dispatch({
        type: Types.UpdateProfile,
        payload: {
          user
        }
      });

      return response;
    });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: AuthMethod.jwt,
        login,
        logout,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
