import { useContext } from 'react';
import { StatusDispatchContext, ACTIONS } from './index';
import { getStatus } from '../../services/status';
import { TStatus } from '../../services/types';

const useStatusDispatch = () => {
  const dispatch = useContext(StatusDispatchContext);
  if (!dispatch) {
    throw new Error('useStatusDispatch should be used inside StatusProvider');
  }
  return dispatch;
};

const useHelpers = () => {
  const dispatch = useStatusDispatch();
  const setStatuses = (status: TStatus[]) =>
    dispatch({ type: ACTIONS.SET_STATUSES, payload: { status } });
  return {
    setStatuses
  };
};

const useStatusAPI = () => {
  const { setStatuses } = useHelpers();

  const loadStatus = () =>
    getStatus()
      .then((response) => {
        const { items } = response;
        setStatuses(items);
        return response;
      })
      .catch((error) => console.error('something failed loading the status', error));

  return {
    loadStatus
  };
};

export default useStatusAPI;
