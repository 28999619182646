import { postJsonRequest } from '../utils/fetch';
import { NavItemProps } from '../components/NavSection';

export enum Roles {
  admin = 'admin',
  operator = 'operator',
  customer = 'customer'
}

interface LoginRequest {
  username: string;
  password: string;
}

export interface IUserLinks {
  subheader: string;
  items: NavItemProps[];
}
export interface IUserRoutes {
  [key: string]: string | { [key: string]: boolean | string };
}
export type TUserGrants = {
  [key: string]: {
    delete: boolean;
    edit: boolean;
    create: boolean;
  };
} | null;

export interface IUserLogged {
  firstname: string;
  id: string;
  lastname: string;
  status: string;
  token: string;
  role: Roles;
  email: string;
  routes: IUserRoutes;
  links: IUserLinks[];
  grants: TUserGrants;
  sellerId?: number;
  sellerProviderId: string;
}
export const login = (credentials: LoginRequest) =>
  postJsonRequest<LoginRequest, IUserLogged>('user/login', credentials);

interface SoftLoginResponse {
  token: string;
}
export const softLogin = () => postJsonRequest<{}, SoftLoginResponse>('user/soft-login', {});
