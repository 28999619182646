import { useReducer } from 'react';
// import { getClientById } from './orderBusinessLogic';
import { OrderContext, OrderDispatchContext, orderInitialState } from './orderContext';
import { OrderReducer } from './reducer';

export const OrderProvider = ({ children }: any) => {
  const [store, dispatch] = useReducer(OrderReducer, orderInitialState);

  return (
    <OrderDispatchContext.Provider value={dispatch}>
      {/* <OrderBusinessLogicContext.Provider value={mutators}> */}
      <OrderContext.Provider value={store}>{children}</OrderContext.Provider>
      {/* </OrderBusinessLogicContext.Provider> */}
    </OrderDispatchContext.Provider>
  );
};
