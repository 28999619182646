import { Suspense, lazy, useContext } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { AuthContext } from '../contexts/JWTContext';
import useOrderInfoCleanup from '../hooks/useOrderInfoCleanUp';
import { PATH_DASHBOARD, PATH_AUTH } from './paths';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes(PATH_DASHBOARD.root);

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useContext(AuthContext);

  useOrderInfoCleanup();

  const protectedRoutes = [
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ index: true, element: <Dashboard /> }]
    },
    {
      path: 'order',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to={PATH_DASHBOARD.orders.list} replace /> },
        { path: 'list', element: <OrderList /> },
        { path: 'client', element: <OrderClient /> },
        { path: 'create', element: <OrderForm /> },
        { path: ':id/edit', element: <OrderFormEdit /> }
      ]
    },
    {
      path: 'notifications',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to={PATH_DASHBOARD.notifications.list} replace /> },
        { path: 'list', element: <NotificationsList /> }
      ]
    },
    {
      path: 'history',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to={PATH_DASHBOARD.history.list} replace /> },
        { path: 'list', element: <HistoryList /> }
      ]
    },
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to={PATH_DASHBOARD.user.list} replace /> },
        { path: 'list', element: <UserList /> },
        { path: 'profile', element: <UserProfile /> },
        { path: 'create', element: <UserCreate /> },
        { path: ':id/edit', element: <UserCreate /> }
      ]
    }
  ]
    .filter((route) => (!user || !user.routes ? false : user.routes[`/${route.path}`]))
    .map((route) => {
      if (user) {
        const { routes } = user;
        const currentRoute = routes[`/${route.path}`];

        // display any screen
        if (currentRoute === '*') {
          return route;
        }

        route.children = route.children.filter((childRoute) =>
          childRoute.path
            ? (currentRoute as { [key: string]: boolean | string })[childRoute.path]
            : true
        );

        return route;
      }

      return route;
    });

  const applicationRoutes = [
    ...protectedRoutes,
    // Auth Routes
    {
      path: 'auth',
      children: [
        {
          index: true,
          element: <Navigate to={PATH_AUTH.login} replace />
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <AuthGuard>
              <Navigate to={PATH_DASHBOARD.root} replace />
            </AuthGuard>
          )
        }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: (
        <AuthGuard>
          <LogoOnlyLayout />
        </AuthGuard>
      ),
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    }
  ];

  return useRoutes(applicationRoutes);
}

// IMPORT COMPONENTS

// Auth
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// User
const UserList = Loadable(lazy(() => import('../pages/users/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/users/UserCreate')));
const UserProfile = Loadable(lazy(() => import('../pages/users/UserProfile')));
// Orders
const OrderList = Loadable(lazy(() => import('../pages/orders/OrderList')));
const OrderClient = Loadable(lazy(() => import('../pages/orders/OrderClient')));
const OrderForm = Loadable(lazy(() => import('../pages/orders/OrderForm')));
const OrderFormEdit = Loadable(lazy(() => import('../pages/orders/OrderFormEdit')));
// Notifications
const NotificationsList = Loadable(lazy(() => import('../pages/notifications/NotificationsList')));
// History
const HistoryList = Loadable(lazy(() => import('../pages/history/HistoryList')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
