export const COOKIES_PREFIX = 'PERKINS';

type TEnvironmentKeys = 'dev' | 'local' | 'prod' | 'qa' | 'stage';

export const COOKIES_DOMAINS: Record<TEnvironmentKeys | string, '.llanquihue.com' | 'localhost'> = {
  dev: '.llanquihue.com',
  local: 'localhost',
  prod: '.llanquihue.com',
  qa: '.llanquihue.com',
  stage: '.llanquihue.com'
};
