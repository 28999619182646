import Pusher from 'pusher-js';
import { isDevEnvironment, REACT_APP_PUSHER_KEY } from '../config/environment';

Pusher.logToConsole = isDevEnvironment;

const pusher = new Pusher(REACT_APP_PUSHER_KEY || '', {
  cluster: 'us2'
});

export default pusher;
