import log from 'loglevel';
import { isDevEnvironment } from '../config/environment';

log.setLevel(isDevEnvironment ? log.levels.DEBUG : log.levels.INFO);

const loggerWrapper = () => {
  if (!isDevEnvironment) {
    return log;
  }

  const mock = () => null;

  return {
    getLogger: () => ({ error: mock, warn: mock, info: mock, trace: mock, debug: mock })
  };
};

export default loggerWrapper();
