import { getJsonRequest, patchJsonRequest } from '../utils/fetch';

export enum INotificationStatus {
  'unread' = 1,
  'read' = 2
}
export enum INotificationType {
  'ot_new' = 1,
  'ot_take' = 2,
  'ot_dispatched',
  'ot_document'
}
export interface IOriginalNotification {
  id: string;
  type: INotificationType;
  title: string;
  url: string;
  description: string;
  object_id: string;
  status: INotificationStatus;
  createdAt: string;
  updatedAt: string;
}

export interface INotification {
  id: string;
  type: INotificationType;
  title: string;
  url: string;
  description: string;
  objectId: string;
  status: INotificationStatus;
  createdAt: string;
  updatedAt: string;
}
type TGetNotifications = (
  query?: string,
  page?: number,
  limit?: number
) => Promise<{ total: number; notifications: INotification[] }>;

export const getNotifications: TGetNotifications = (query = '', page = 0, limit = 25) => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    query: query.toString()
  });

  return getJsonRequest<{ success: boolean; totalCount: number; data: IOriginalNotification[] }>(
    `notifications?${params.toString()}`
  ).then(({ totalCount, data }) => {
    const updatedData = data.map((notification) => ({
      ...notification,
      objectId: notification.object_id
    }));
    return { total: totalCount, notifications: updatedData };
  });
};

export const checkNotification = (id: string): Promise<{ success: boolean; error: string }> =>
  patchJsonRequest<{}, { success: boolean; error: string }>(`notifications/check/${id}`, {});
